import { createSlice } from '@reduxjs/toolkit';

const profileSlice = createSlice({
    name: 'profile',
    initialState: [],
    reducers: {
        updateProfile: (state, action) => {
            state.push(action.payload.value)
        },
        loog : (state, action) => {
            // console.log(action.payload.value);
        }
    }
})

export const { updateProfile, loog } = profileSlice.actions;
export default profileSlice;