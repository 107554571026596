import { Route, Redirect } from "react-router-dom";
import React, {Suspense, lazy, Fragment} from 'react';

import {
    ToastContainer,
} from 'react-toastify';
import { Button } from "react-bootstrap";
const Dashboards = lazy(() => import('../../DemoPages/Dashboards'));

const Widgets       = lazy(() => import('../../DemoPages/Widgets'));
const Elements      = lazy(() => import('../../DemoPages/Elements'));
const Components    = lazy(() => import('../../DemoPages/Components'));
const Charts        = lazy(() => import('../../DemoPages/Charts'));
const Forms         = lazy(() => import('../../DemoPages/Forms'));
const Tables        = lazy(() => import('../../DemoPages/Tables'));
const Auths         = lazy(() => import('../../Pages/Auths'));
const Profile       = lazy(() => import('../../DemoPages/Profile'));
const Admin         = lazy(() => import('../../DemoPages/Admin'));
const CheckStatus   = lazy(() => import('../../Pages/CheckStatus'));
const Panduan       = lazy(() => import('../../Pages/Panduan'));
const Leads         = lazy(() => import('../../Pages/Leads'));

const authenticate = () => {
    const token = localStorage.getItem('token');
    if(token === null){
        return false
    }else{
        return true
    }
};

const PrivateAuth = ({ component: Component, ...rest }) => {
    return (
        <Route
        {...rest}
        render={(props) => 
            authenticate ? ( <Component {...props} /> ) : (
                <Redirect to="/auths/login"/>
            )
        }
        />
    )
};

const AppMain = () => {

    return (
        <Fragment>

            {/* Components */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            Please wait while we load all the Components examples
                            <small>Because this is a demonstration we load at once all the Components examples. This wouldn't happen in a real live app!</small>
                        </h6>
                        {/* <Button></Button> */}
                    </div>
                </div>
                }
            >
                <PrivateAuth path="/components" component={Components}/>
            </Suspense>

            {/* Forms */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            Please wait while we load all the Forms examples
                            <small>Because this is a demonstration we load at once all the Forms examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateAuth path="/forms" component={Forms}/>
            </Suspense>

            {/* Charts */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load all the Charts examples
                            <small>Because this is a demonstration we load at once all the Charts examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateAuth path="/charts" component={Charts}/>
            </Suspense>

            {/* Tables */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-5">
                            Please wait while we load all the Tables examples
                            <small>Because this is a demonstration we load at once all the Tables examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateAuth path="/tables" component={Tables}/>
            </Suspense>

            {/* Elements */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load all the Elements examples
                            <small>Because this is a demonstration we load at once all the Elements examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateAuth path="/elements" component={Elements}/>
            </Suspense>

            {/* Dashboard Widgets */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load all the Dashboard Widgets examples
                            <small>Because this is a demonstration we load at once all the Dashboard Widgets examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateAuth path="/widgets" component={Widgets}/>
            </Suspense>

            {/* Dashboards */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load all the Dashboards examples
                            <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateAuth path="/dash" component={Dashboards}/>
            </Suspense>


            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load all the Dashboards examples
                            <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/auths" component={Auths} />
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load all the Dashboards examples
                            <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <PrivateAuth path="/profile" component={Profile} />
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                        </h6>
                    </div>
                </div>
            }>
                <PrivateAuth path="/admin" component={Admin} />
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    
                </div>
            }>
                <PrivateAuth path="/check-status" component={CheckStatus}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    
                </div>
            }>
                <PrivateAuth path="/panduan" component={Panduan}/>
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    
                </div>
            }>
                <PrivateAuth path="/leads" component={Leads}/>
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/auths/login"/>
            )}/>
            {/* <Route exact path="/" component={}/> */}
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;